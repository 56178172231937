

body {
  /* margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  background-color: lightgrey;
/* 
  background: url(/img/bg.png) no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 700px; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header{
  background: url(./img/bg.png) no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 732px;


  
}
@media screen and (max-width: 670px){
  header{
    min-height: 500px;
  }
}

.container .section{
    width: 100%;
}

.section{
  padding-top: 2vw;
  padding-bottom: 2vw;
}
.tabs {
  background: lightgrey;
}
.tabs .indicator{
  background-color: black;
  }

.tabs .tab a:focus, .tabs .tab a:focus.active{
  background: transparent;
  }

.copyright{
  color: #777;
}

.text-xs-center{
  text-align: center!important;
}

/* .mx{
  margin-left: 48px!important;
  margin-right: 48px!important;
}

.my{
  margin-left: 48px!important;
  margin-right: 48px!important;
} */

.ToTheTopIcon{
  position: fixed; /* Fixed/sticky position */
  bottom: 20px; /* Place the button at the bottom of the page */
  right: 100px; /* Place the button 30px from the right */
  z-index: 99; /* Make sure it does not overlap */
  border: none; /* Remove borders */
  outline: none; /* Remove outline */
  /* background-color: red; Set a background color */
  color: white; /* Text color */
  cursor: pointer; /* Add a mouse pointer on hover */
  padding: 15px; /* Some padding */
  border-radius: 10px; /* Rounded corners */
}
.ToTheTopIcon:hover {
  background-color:black; /* Add a dark-grey background on hover */
}

.video{
  padding: 20px;
}

/* 
.travelNoteNavbar{
  
  position: fixed;
  bottom: 250px;
  display: inline;

  color: blue;
} */

a {
  color: black;
}

/* .travelNoteNavbar .new{
  display: none;
} */


.nav{
  display: none;

}

.nav.new{
  display: inline;
  position: fixed;
  top: 100px;
  padding-left: 10px;
  border-left-style: solid;
  
}

/* #travelNoteBeijing{
  background: black;
} */

#eng{
  color: grey;
}

/* .titles{
  border-bottom-width: 1px;
  border-bottom-style: dashed;
} */

.title{
  border-bottom: solid;
  font-family: 'MaShanZheng';
}

.titleENG{
  border-bottom: solid;
  font-family: 'Vidaloka';
}

.school{
  font-family: 'AbrilFatface';
}

.titles{
  padding-top: 10px;
}

@font-face{
  font-family: 'MaShanZheng';
  src: url('./fonts/MaShanZheng-Regular.ttf')
}

@font-face{
  font-family: 'AbrilFatface';
  src: url('./fonts/AbrilFatface-Regular.ttf')
}

@font-face{
  font-family: 'Parisienne';
  src: url('./fonts/Parisienne-Regular.ttf')
}

@font-face{
  font-family: 'Satisfy';
  src: url('./fonts/Satisfy-Regular.ttf')
}

@font-face{
  font-family: 'Vidaloka';
  src: url('./fonts/Vidaloka-Regular.ttf')
}

@font-face{
  font-family: 'Castoro';
  src: url('./fonts/Castoro-Regular.ttf')
}

@font-face{
  font-family: 'Castoro-Italic';
  src: url('./fonts/Castoro-Italic.ttf')
}
/* @font-face{
  font-family: 'Cookie';
  src: url('./fonts/Cookie-Regular.ttf')
} */

/* @font-face{
  font-family: 'Amiri';
  src: url('./fonts/Amiri-Regular.ttf')
} */

/* @font-face{
  font-family: 'Amiri-Bold';
  src: url('./fonts/Amiri-Bold.ttf')
} */

/* @font-face{
  font-family: 'Rajdhani-SemiBold';
  src: url('./fonts/Rajdhani-SemiBold.ttf')
} */

.smallTitle{
  padding-left: 20px;
  font-weight: lighter;
  font-family: 'AbrilFatface';
}

.titleDate{
  font-weight: normal;
  font-family: 'AbrilFatface';
}

.titleMap{
  color: grey;
  padding-left: 1px;
}

.bigTitle{
  font-size: xx-large;
  padding-left: 10px;
  padding-bottom: 10px;
  font-family: 'MaShanZheng';
}


/* .travelDetail li{
  width: 30%;
  display: inline-block;
}
.travelDetail ul{
  white-space: nowrap;
} */

.travelDetail{
  margin-top: 10px;
  padding: 20px;
  border-style: dashed;
  border-width: 1px;
}

.info{
  font-family: 'MaShanZheng';
}

.btn-flat{
    background: none;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;  
  }
  
  btn-flat.active.focus, btn-flat.active:focus,
  btn-flat.focus, btn-flat:active.focus, 
  btn-flat:active:focus, btn-flat:focus {
    outline: none;
    box-shadow: none;
  }

.titleContent{
  border-style: dashed;
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
}



.verysmallTitle{
  text-decoration: underline;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.map{
  background: url(./img/world.png);
  background-repeat: no-repeat;
  background-position-x: center;
  min-height: 470px;
}

.Chinaplane{
  animation-name: fly1;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}

/* .Chinaplane:hover{
  
} */

@keyframes fly1{
  from{ transform: translateX(0) }
  to{ transform: translate(500px,100px)}
}

.thailandplane{
  animation-name: fly2;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}


@keyframes fly2{
  from{ transform: translateX(0) }
  to{ transform: translate(390px,150px)}
}

.usaplane{
  animation-name: fly3;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}



@keyframes fly3{
  from{ transform: translate(300px, 300px) }
  to{ transform: translate(-150px,50px)}
}

.canadaplane{
  left: 0px;
  animation-name: fly4;
  animation-duration: 5s;
  top: 200px;
  animation-iteration-count: infinite;
}



@keyframes fly4{
  from{ transform: translate(200px, -50px) }
  to{ transform: translate(-200px,0)}
}


.ToTheTopIcon{
  display: none;

}

.ToTheTopIcon.new2{
  display: inline;
  position: fixed;
}

.gushi{
  border-style: solid;
  border-width: 1px;
  padding: inherit;
}

#zhuanshuContent{
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: minmax(100px, auto);
  grid-gap: 10px;
  margin: 0 auto;
}

.zhuanshuContent4{
  grid-column: 1/7;
  grid-row: 1/5;
}

.zhuanshuContent2{
  grid-column: 7/13;
  grid-row: 1/3;
}

.zhuanshuContent7{
  grid-column: 7/13;
  grid-row: 3/5;
}

.zhuanshuContent1{
  grid-column: 1/9;
  grid-row: 5/7;
}

.zhuanshuContent8{
  grid-column: 9/13;
  grid-row: 5/6;
}

.zhuanshuContent5{
  grid-column: 9/13;
  grid-row: 6/7;
}


.zhuanshuContent3{
  grid-column: 1/5;
  grid-row: 7/9;
}


.zhuanshuContent6{
  grid-column: 5/13;
  grid-row: 7/8;
}

#kaishuContent{
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: minmax(100px, auto);
  grid-gap: 10px;
  margin: 0 auto;
}

.kaishuContent1{
  grid-column: 1/6;
  grid-row: 1/3;
}

.kaishuContent2{
  grid-column: 6/13;
  grid-row: 1/2;
}

.kaishuContent3{
  grid-column: 6/13;
  grid-row: 2/3;
}


/* dashboard */
.grid{
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 10px;
  box-sizing: border-box;
}
.portfolio{
  padding: 30px 20px;
}
.portfolio h3{
  margin-bottom: 0;
  text-align: center;
  /* font-family: 'Parisienne'; */
  font-family: 'Satisfy';
  font-size: 2em;
}
.projects{
  margin: 20px 0;
}
.projects a{
  grid-column: 1/9;
  display: flex;
  margin: 20px 0
}
.projects a *{
  margin: 0 20px;
  max-width: 40%;
  align-self: center;
}
.projects p{
  color: black;
  font-family: 'Parisienne';
  font-size: 1.5em;
}


.interests{
  padding: 30px 20px;
}
.interests h3{
  margin-bottom: 0;
  text-align: center;
  /* font-family: 'Parisienne'; */
  font-family: 'Satisfy';
  font-size: 2em;
}
.interests ul{
  margin: 40px 0;
}
.interests li{
  padding: 10px;
  grid-column: span 4;
  text-align: center;
}
.interests img{
  height: 150px;
  margin: 10px auto;
  border-radius: 30px;
}
.interests p{
  text-align: center;
  margin: 0 20px;
  color: black;
  font-family: 'Parisienne';
  font-size: 1.5em;
}


.skills{
  padding: 30px 20px;
}
.skills h3{
  margin-bottom: 0;
  text-align: center;
  /* font-family: 'Parisienne'; */
  font-family: 'Satisfy';
  font-size: 2em;
}
.skills ul{
  margin: 40px 0;
}
.skills li{
  padding: 10px;
  grid-column: span 4;
  text-align: center;
}
.skills img{
  height: 170px;
  margin: 10px auto;
  border-radius: 30px;
}
.skills p{
  text-align: center;
  margin: 0 20px;
  color: black;
  font-family: 'Parisienne';
  font-size: 1.5em;
}



/* small tablet styles */
@media screen and (min-width: 600px){

  .projects{
    margin-top: 40px;
  }
  .projects a{
    grid-column: span 4;
    display: block;
    margin: 20px 0;
  }
  .projects a:last-child{
    grid-column: 3/7;
  }
  .projects a *{
    margin: 10px;
    max-width: 60%;
    margin: 10px 20%;
  }
  .projects p{
    text-align: center;
  }

.interests{
    padding: 30px 100px;
  }

  .skills{
    padding: 30px 100px;
  }

}

/* large tablet & laptop styles */
@media screen and (min-width: 800px){


  .grid{
    max-width: 1060px;
    margin: 0 auto;
    width: 100%;
  }

  .projects a:nth-child(1){
    grid-column: 2/4;
  }
  .projects a:nth-child(2){
    grid-column: 4/6;
    padding-top: 140px;
  }
  .projects a:nth-child(3){
    grid-column: 6/8;
  }
  .projects a *{
    max-width: 80%;
    margin: 30px 10%;
  }

  .interests{
    padding: 30px 20px;
  }
  .interests ul{
    margin: 40px auto;
  }
  .interests li{
    grid-column: span 2;
  }
  .interests p{
    max-width: 600px;
    margin: 40px auto;
  }
  

  .skills{
    padding: 30px 20px;
  }
  .skills ul{
    margin: 40px auto;
  }
  .skills li{
    grid-column: span 2;
  }
  .skills p{
    max-width: 600px;
    margin: 40px auto;
  }


}

/* desktop styles */
@media screen and (min-width: 1000px){

  .projects a:nth-child(1){
    grid-column: 1/3;
  }
  .projects a:nth-child(2){
    grid-column: 4/6;
    padding-top: 140px;
  }
  .projects a:nth-child(3){
    grid-column: 7/9;
  }
  .projects a *{
    max-width: 100%;
    margin: 30px 0%;
  }

.interests {
    padding: 90px 0;
  }

  .skills {
    padding: 90px 0;
  }

}



.main-nav {
  /* background-color: #f4f4f4; */
  background-color: lightgrey;
  /* padding: 20px; */
  position: sticky;
  top: 0px;
}
.main-nav ul{
  white-space: nowrap;
  max-width: 1200px;
  margin: 0 auto;
}
.main-nav li {
  width: 25%;
  display: inline-block;
  font-size: 24px;
}
.main-nav li a {
  text-decoration: none;
  /* color: #4b4b4b; */
  color:black;
  font-family: 'Vidaloka';
  font-size: 0.7em;
}
/* .main-nav li a.join {
  color: #f63232;
} */

@media screen and (max-width: 600px){

  /* .main-nav {
    display: none;
  } */

  /* .main-nav  li{
    font-size: 20px;
    position: relative;
    display: block;
    width: 100%;
} */
}

.projects a:hover{
  position: relative;
  top: -5px;
}

.taoyititle{
  font-family: 'MaShanZheng';
}

#huaContent{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(100px, auto);
  grid-gap: 10px;
  margin: 0 auto;
}

.huaContent3{
  grid-column: 1/2;
  grid-row: 1/3;
}

.huaContent1{
  grid-column: 2/3;
  grid-row: 1/2;
}

.huaContent2{
  grid-column: 2/3;
  grid-row: 2/3;
}

.YXdetail{
  border-left-style: solid;
  padding-left: 10px;
  border-width: 1px;
}

.YXdetailP{
  font-family: 'Castoro';
}

.sidenavtext{
  font-family: 'Castoro';
}


/* .signinform{
  bottom: 0;
}

html{
  min-height: 100%;
}

.signpage{
  margin: 0;
  padding: 0;
  background: url(./img/bg.png) no-repeat;

  background-size: 100%;
  background-position: center;
  min-height: 100%; 

}

.signinform{
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 10px;
  margin: 0 auto;
}

.signinform1{
  grid-column: 1/5;
  grid-row: 2/3;
} */


.signinform{
  padding-left: 20px;
  padding-top: 30%;
  width: 60%;
}

.signupform{
  padding-left: 20px;
  padding-top: 20%;
  width: 60%;
}

.signTitle{
  /* border-bottom: solid; */
  font-family: 'Castoro';
}

.numAuthform{
  padding-left: 20px;
  padding-top: 20%;
  width: 30%;
}




.numAuthTitle{
  font-family: 'Castoro';
}


input[type=text]:not(.brower-default):focus:not([readonly]),
input[type=email]:not(.brower-default):focus:not([readonly]),
input[type=password]:not(.brower-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]){
  border-color: black;
  box-shadow: none;
}



/* .Comment{
  padding-top: 10px;
} */

.commentContent{
  padding: 20px;

}



.commentbtn{
  font-family: 'Castoro';
}

.smallnotenav{
  position: fixed;
  bottom: 10px;
  left: 5px;
  z-index: 1;
}


/* .nav-menu {
  left: -100%;
  transition: 350ms;
  
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
} */

.YX-Ps{
  border-style: solid;
  border-width: 1px;
  padding-top: 20px;
}